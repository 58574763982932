<template>
    <section>

        <b-field v-if="editableArtisticProfile.files && editableArtisticProfile.files.length" :label="$t('Riders')" key="artistic-profile-riders">
            <b-tag v-for="(file, index) in editableArtisticProfile.files"
                   :key="file.uuid"
                   closable
                   attached
                   type="is-default"
                   size="is-medium"
                   :aria-close-label="$t('delete')"
                   @close="deleteFile(file, index)">

                {{ file.file_name }}
                <b-icon v-if="isDeleting" icon="spinner" custom-class="fa-spin ml-3"></b-icon>
            </b-tag>
        </b-field>

        <b-field v-else :label="$t('Riders')" :message="$_p_formGetError('file')" :type="{'is-danger': $_p_formHasError('file')}">

            <b-upload v-model="file" @input="uploadTecRider" key="upload-artistic-profile-riders" accept="application/pdf">
                <a class="button is-primary" @click="preventUploadIfProfileNotCreated">
                    <b-icon v-if="isLoading" icon="spinner" custom-class="fa-spin"> </b-icon>
                    <b-icon v-else icon="upload"> </b-icon>
                    <span>{{$t('Upload rider')}}</span>

                </a>
            </b-upload>

        </b-field>
    </section>
</template>

<script>
    import {mapMutations} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {
        ADD_ARTISTIC_PROFILE_FILES,
        DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX
    } from "@/store/modules/artisticProfiles/mutation-types";

    export default {
        props:{
        },
        data: function () {
            return {
                maxRidersNumber : 2,
                file: null,
                isDeleting: false,
                isLoading: false,
            };
        },
        computed: {
            editableArtisticProfile(){
                return this.$store.state.artisticProfiles.editedProfile;
            },
            artisticProfileIsStoredToDatabase(){
                return undefined !== this.editableArtisticProfile.id;
            },
        },
        methods: {
            ...mapMutations('artisticProfiles',[
                ADD_ARTISTIC_PROFILE_FILES,
                DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX,
            ]),
            preventUploadIfProfileNotCreated(event){
                if (!this.artisticProfileIsStoredToDatabase){
                    event.preventDefault();
                    this.$buefy.dialog.alert('Вы сможете загрузить файлы только после сохранения проекта.');
                }
            },
            uploadTecRider(file){
                this.isLoading = true;
                let formData = new FormData();

                formData.append('description', 'технический райдер');
                formData.append('file', file);
                this.$_p_formPost('artistic-profiles/' + this.editableArtisticProfile.id + '/files', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(res => {
                    if (201 === res.status){
                        this[ADD_ARTISTIC_PROFILE_FILES](res.data);
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            deleteFile(file, index){
                this.isDeleting = true;
                this.$_p_formDelete('artistic-profiles/' + this.editableArtisticProfile.id + '/files/' + file.uuid )
                .then(res => {
                    if (200 === res.status){
                        this[DELETE_ARTISTIC_PROFILE_FILE_BY_INDEX](index);
                    }
                }).finally(() => {
                    this.isDeleting = false;
                });
            }
        },
        mixins:[formHelperMixin],
        name: "ArtisticProfileFormRiders"
    };
</script>

<style scoped>

</style>