export default {
    methods: {
        getImgUrl(image){
            return image.original_src;
        },
        getImgThumbUrl(image){
            let path = '/storage/' +
                image.src.slice(0,image.src.lastIndexOf('/')) +
                '/thumbnails' +
                image.src.slice(image.src.lastIndexOf('/'));

            return process.env.VUE_APP_BACKEND_URL + path;
        },
    }
};