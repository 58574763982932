import { render, staticRenderFns } from "./ArtisticProfileFormRiders.vue?vue&type=template&id=37bb1a56&scoped=true&"
import script from "./ArtisticProfileFormRiders.vue?vue&type=script&lang=js&"
export * from "./ArtisticProfileFormRiders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37bb1a56",
  null
  
)

export default component.exports