<template>
    <section class="">
        <div class="columns">
            <div class="column">
                <artistic-profile-form v-if="editedProfile"/>
            </div>
        </div>
    </section>
</template>

<script>
    import ArtisticProfileForm from "@/components/Forms/ArtisticProfileForm";
    import {mapActions, mapMutations, mapState} from "vuex";
    import {
        DELETE_ARTISTIC_PROFILE_FROM_LIST,
        SET_EDITED_ARTISTIC_PROFILE
    } from "@/store/modules/artisticProfiles/mutation-types";
    import {DESTROY_ARTISTIC_PROFILE, GET_ARTISTIC_PROFILE} from "@/store/modules/artisticProfiles/actions-types";
    export default {
        name: "EditArtisticProfile",
        title(){ return this.$t('Edit artistic profile');},
        components: {ArtisticProfileForm},
        methods: {
            ...mapMutations('artisticProfiles', [
                SET_EDITED_ARTISTIC_PROFILE,
                DELETE_ARTISTIC_PROFILE_FROM_LIST,
            ]),
            ...mapActions('artisticProfiles', [
                GET_ARTISTIC_PROFILE,
                DESTROY_ARTISTIC_PROFILE,
            ]),
            confirmLeaveWithoutPublish(next){
                this.$buefy.dialog.confirm({
                    title: this.$t('The project was not published'),
                    message: this.$t('Remove unpublished project?'),
                    confirmText: this.$t('Yes'),
                    cancelText: this.$t('No'),
                    onConfirm: () => {
                        this[DESTROY_ARTISTIC_PROFILE](this.editedProfile.id)
                            .then(() => {
                                this[DELETE_ARTISTIC_PROFILE_FROM_LIST](this.editedProfile.id);
                                this[SET_EDITED_ARTISTIC_PROFILE](null);
                                next();
                            });
                    },
                    onCancel: () => {
                        this[SET_EDITED_ARTISTIC_PROFILE](null);
                        next();
                    }
                });
            },
        },
        computed: {
            ...mapState('artisticProfiles', [
                'editedProfile'
            ]),
        },
        async mounted() {
            if (!this.$route.params.profile){
                this[GET_ARTISTIC_PROFILE](this.$route.params.id)
                    .then(res => {
                        this[SET_EDITED_ARTISTIC_PROFILE](res.data);
                    });
            } else {
                this[SET_EDITED_ARTISTIC_PROFILE](this.$route.params.profile);
            }
        },
        beforeRouteLeave(to, from, next){
            if (this.editedProfile && !this.editedProfile.published){
                this.confirmLeaveWithoutPublish(next);
            } else {
                this[SET_EDITED_ARTISTIC_PROFILE](null);
                next();
            }
        },
    };
</script>

<style scoped>

</style>