<template>
    <div class="field">
        <b-field :loading="imagesLoading" :label="$t('Gallery')">
            <b-upload v-model="newImagesToUpload"
                      accept="image/*"
                      @input="uploadImages"
                      multiple>
                <a class="button is-primary"
                   :class="{'is-loading': imagesLoading}">
                    <b-icon icon="upload"></b-icon>
                    <span>{{$t('Upload images')}}</span>
                </a>
            </b-upload>
        </b-field>

        <slot></slot>

        <figure class="mr-1 mb-2 image is-96x96 is-inline-block form-gallery-figure" v-for="(image, index) in images" :key="'image-' + image.uuid">
            <img v-img="{group:'gallery', src:image.original_url}" :src="image.preview_url || image.original_url" alt="">
            <b-button size="is-small"
                      type="is-danger"
                      @click.prevent="$emit('delete-image', {image:image, index: index})"
                      :class="{'is-loading': imageDeletingUuid ===  image.uuid}"
                      class="delete-image-icon">
                <b-icon icon="trash">
                </b-icon>
            </b-button>
        </figure>
    </div>
</template>

<script>
    import imgUrlGenerator from "@/mixins/imgUrlGenerator";

    export default {
        props: {
            images: {
                default: function (){
                    return {};
                }
            },
            imagesLoading: {
                type: Boolean,
                default: false,
            },
            imageDeletingUuid: String,
        },
        data: function () {
            return {
                newImagesToUpload: [],
            };
        },
        methods: {
            uploadImages(){
                this.$emit('upload-images', this.newImagesToUpload);
                this.newImagesToUpload = [];
            },
            deleteImage(image, index){
                this.$emit('delete-image', {
                    image: image,
                    index: index,
                });
            },
        },
        mixins: [imgUrlGenerator],
        name: "FormGallery"
    };
</script>

<style scoped>
    .delete-image-icon {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
    }
    .form-gallery-figure {
        overflow: hidden;
    }
</style>