
export default {
    data(){
        return {
            relationsMap: {
                instruments: {
                    rule: 'only',
                    types: ['show', 'solo_performer', 'band', 'dj_instrumental'],
                },
                musical_stylistic_options: {
                    rule: 'only',
                    types: ['show', 'solo_performer', 'band', 'dj'],
                },
                dancing_stylistic_options: {
                    rule: 'only',
                    types: ['dancer', 'show'],
                },
                show_stylistic_options: {
                    rule: 'only',
                    types: ['show'],
                },
                features: {
                    rule: 'only',
                    types: ['band', 'show'],
                }
            }
        };
    },
    computed: {
        modelCanHasShowFormat(){
            return this.modelCanHasRelation('show_format');
        },
        modelCanHasInstruments(){
            return this.modelCanHasRelation('instruments');
        },
        modelCanHasMusicStylisticOptions(){
            return this.modelCanHasRelation('musical_stylistic_options');
        },
        modelCanHasDancingStylisticOptions(){
            return this.modelCanHasRelation('dancing_stylistic_options');
        },
        modelCanHasShowStylisticOptions(){
            return this.modelCanHasRelation('show_stylistic_options');
        },
        modelCanHasStylisticOptions(){
            return this.modelCanHasDancingStylisticOptions && this.modelCanHasMusicStylisticOptions;
        },
        modelCanHasFeatures(){
            return this.modelCanHasRelation('features');
        },
        editableModelTypeId(){
            if (this.editableArtisticProfile){
                return this.artisticProfile.type_id;
            } else if (this.editedVacancy){
                return this.vacancy.artistic_profile_type_id;
            }
        },
    },
    methods: {
        modelCanHasRelation(relationName){
            let profileType = this.getModelProfileTypeObject();
            if (!profileType){
                return false;
            }

            let result = this.relationsMap[relationName].types.includes(profileType.name);

            return 'only' === this.relationsMap[relationName].rule ? result : !result;
        },
        getModelProfileTypeObject(){
            if (!this.editableModelTypeId){
                return null;
            }

            return  this.artisticProfileTypes.find(type => {
                return type.id === this.editableModelTypeId;
            });
        },
    }
};