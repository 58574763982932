<template>
    <form class="form" @submit.prevent="handleFormSubmit" v-if="artisticProfileTypes && artisticProfile">

        <b-field>
            <b-button @click.prevent="$router.go(-1);" icon-left="chevron-left" size="is-small">{{$t('go back')}}</b-button>
        </b-field>

        <b-field :label="$t('Select profile type')"
                 :type="{'is-danger': $_p_formHasError('type_id')}"
                 :message="$_p_formHasError('type_id') ? $_p_formGetError('type_id'): ''">
            <b-select :placeholder="$t('Select')"

                      @input="clearInstrumentsAndStylisticOptionsRelation,$_p_formClearError('type_id')"
                      v-model="artisticProfile.type_id">
                <option v-for="type in artisticProfileTypes"
                        :key="'profile-type-' + type.id"
                        v-text="$t(type.name)"
                        :value="type.id">
                </option>
            </b-select>
        </b-field>

        <!--SHOW STYLISTIC OPTIONS aka SHOW FORMATS-->
        <b-field v-if="modelCanHasShowStylisticOptions" :label="$t('Show type')">
            <b-select v-model="stylisticOptions.show" :placeholder="$t('Select show type')">
                <option v-for="showType in showStylisticOptions"
                        :key="'show-stylistic-option-' + showType.id"
                        :value="[showType.id]"
                        v-text="$t(showType.name)">
                </option>
            </b-select>
        </b-field>

        <b-field :label="$t('Profile name')"
                 :type="{'is-danger': $_p_formHasError('title')}"
                 :message="$_p_formHasError('title') ?  $_p_formGetError('title') : $t('Your name, nickname or team name')">
            <b-input @input="$_p_formClearError('title')" v-model="artisticProfile.title"> </b-input>
        </b-field>

        <!-- INSTRUMENTS -->
        <b-field :label="$t('Select instrument')"
                 :message="$t('Select instruments you use')"
                 v-if="modelCanHasInstruments">
            <b-select  v-model="artisticProfile.instruments"
                       :placeholder="$t('Select')"
                       multiple native-size="5">
                <option v-for="instrument in instruments"
                        :key="'instrument-' + instrument.id"
                        :value="instrument.id"
                        v-text="$t(instrument.name)">
                </option>
            </b-select>

        </b-field>

        <b-field :label="$t('Select musical styles')"
                 v-if="modelCanHasMusicStylisticOptions">

            <b-select v-model="stylisticOptions.musical"
                      :placeholder="$t('Select')"
                      multiple
                      native-size="5">
                <option v-for="stylisticOption in musicalStylisticOptions"
                        :key="'stylistic-option-' + stylisticOption.id"
                        :value="stylisticOption.id"
                        v-text="$t(stylisticOption.name)">
                </option>
            </b-select>

        </b-field>

        <b-field :label="$t('Select dancing styles')"
                 v-if="modelCanHasDancingStylisticOptions">

            <b-select :placeholder="$t('Select')"
                      v-model="stylisticOptions.dancing"
                      multiple
                      native-size="5">
                <option v-for="stylisticOption in dancingStylisticOptions"
                        :key="'stylistic-option-' + stylisticOption.id"
                        :value="stylisticOption.id"
                        v-text="$t(stylisticOption.name)">
                </option>
            </b-select>

        </b-field>

        <!-- FEATURES -->

        <b-field :label="$t('Select features')" v-if="modelCanHasFeatures">

            <b-checkbox v-model="artisticProfile.features"
                        v-for="feature in artisticProfileFeatures"
                        :disabled="!!(artisticProfile.features.length && !artisticProfile.features.includes(feature.id))"
                        :key="`feature-${feature.id}`"
                        :native-value="feature.id">
                {{ $t(feature.type)}}
            </b-checkbox>

        </b-field>

        <!--CITIES-->
        <cities-list @selected="city => {artisticProfile.city_id = (null === city) ? null : city.id}"
                     :title="$t('Select the main city of work')"
                     :preselected-city-id="editableArtisticProfile.city_id"
                     @clear-error="$_p_formClearError('city_id')"
                     :errors="errors"
                     :has-error="$_p_formHasError('city_id')"
                     :error-message="$_p_formGetError('city_id')">
        </cities-list>

        <b-field>
            <b-checkbox v-model="artisticProfile.ready_for_other_cities">
                {{$t('Ready for work in other cities')}}
            </b-checkbox>
        </b-field>

        <b-field>
            <b-checkbox v-model="artisticProfile.ready_for_abroad">
                {{$t('Ready for abroad contracts')}}
            </b-checkbox>
        </b-field>

        <artistic-profile-youtube-link :artistic-profile="artisticProfile" @updated="link => artisticProfile.prime_promo_link = link"/>

        <b-field :label="$t('Your Instagram profile link')">
            <b-input v-model="artisticProfile.instagram_profile_link">
            </b-input>
        </b-field>

        <b-field :label="$t('Your Facebook profile link')">
            <b-input v-model="artisticProfile.facebook_profile_link">
            </b-input>
        </b-field>

        <!-- LINKS -->

        <additional-links
                :editable-model="artisticProfile"
                @delete-link="deleteArtisticProfileLink"
                @add-link="addArtisticProfileLink"
                :field-label="$t('Additional promo links')"/>


        <!-- IMAGES GALLERY-->

        <artistic-profile-form-gallery :artistic-profile="artisticProfile" />

        <!-- DESCRIPTION -->
        <b-field :label="$t('Description')"
                 :type="{'is-danger' : $_p_formHasError('description')}"
                 :message="$_p_formHasError('description') ? $_p_formGetError('description') : ''">
            <b-input type="textarea"
                     @input="$_p_formClearError('description')"
                     v-model="artisticProfile.description"
                     maxlength="2000">
            </b-input>
        </b-field>

        <!-- FEE -->
        <div class="field">
            <label class="label">{{$t('Fee')}}</label>

            <b-radio v-model="artisticProfile.fee_type" @input="editableArtisticProfile.fee_options = []" native-value="agreed">{{$t('agreed')}}</b-radio>
            <b-radio v-model="artisticProfile.fee_type" @input="editableArtisticProfile.fee_options = []" native-value="fixed">{{$t('fixed')}}</b-radio>

          <p class="help">
<!--            fee type description here-->
          </p>
        </div>

        <div class="field">
            <!--AGREED FEE OPTIONS-->
            <div v-if="'agreed' === artisticProfile.fee_type" >

                <div v-if="!editableArtisticProfile.fee_options.length"
                     key="create-contract-fee-option">

                    <b-field :type="{'is-danger': $_p_formHasError('fee_amount')}">
                        <b-select v-model="feeCurrency">
                            <option value="UAH">UAH</option>
                            <option value="USD">USD</option>
                        </b-select>
                        <b-input @input="$_p_formClearError('fee_amount')"
                                 type="number"
                                 :placeholder="$t('amount')"
                                 v-model.number="feeAmount"></b-input>
                    </b-field>
                    <p class="help is-danger" v-if="$_p_formHasError('fee_amount')" >{{$_p_formGetError('fee_amount')[0]}}</p>

                    <b-field>
                        <p class="control">
                            <b-button type="is-primary" size="is-small" @click="addFeeOption">{{$t('Save')}}</b-button>
                        </p>
                    </b-field>

                    <p class="help">{{$t('You can specify a lower threshold for the fee. We do not show this information to third parties, but it will help us offer you the most suitable vacancies.')}}</p>

                </div>

                <!-- display threshold fee option-->

                <div v-else key="show-contract-fee-option">

                    <b-field >
                        <b-select size="is-small" :value="editableArtisticProfile.fee_options[0].currency" disabled>
                            <option value="UAH">UAH</option>
                            <option value="USD">USD</option>
                        </b-select>
                        <b-input size="is-small" disabled v-model.number="editableArtisticProfile.fee_options[0].amount"></b-input>
                        <p class="control">
                            <b-button type="is-danger" size="is-small" @click="setEditableArtisticProfileFeeOptions([])">{{$t('delete')}}</b-button>
                        </p>
                    </b-field>
                    <p class="help">{{$t('We do not show this information to third parties, but it will help us offer you the most suitable vacancies')}}</p>

                </div>

            </div>

            <!--FIXED FEE OPTIONS-->
            <div v-else key="fixed-fee-type">
                <div class="control">

                <!--display fee options-->
                    <div class="control mb-3" v-for="(feeOption, index) in editableArtisticProfile.fee_options"
                         :key="'fee-option-' + index">

                        <b-field>
                            <b-select size="is-small" :value="feeOption.currency" disabled>
                                <option value="UAH">UAH</option>
                                <option value="USD">USD</option>
                            </b-select>
                            <b-input size="is-small" :value="feeOption.amount" disabled></b-input>
                            <p class="control">
                                <b-button type="is-danger" size="is-small" @click="deleteFeeOption(feeOption, index)">{{$t('delete')}}</b-button>
                            </p>

                        </b-field>
                        <p class="help"><b-icon icon="hand-point-right"></b-icon> {{ feeOption.description || $t('no description') }}</p>
                    </div>

                  <!-- ADD FEE OPTION -->
                    <b-field>
                        <b-select v-model="feeCurrency">
                            <option>UAH</option>
                            <option>USD</option>
                        </b-select>
                        <b-input type="number" v-model="feeAmount" :placeholder="$t('amount')"></b-input>

                    </b-field>
                  <b-field>
                    <b-input type="text" :placeholder="$t('variant description')" @keyup.enter="addFeeOption" v-model="feeDescription"></b-input>

                  </b-field>
                    <b-field>
                        <p class="control">
                            <b-button type="is-primary" size="is-small" @click="addFeeOption">{{$t('add')}}</b-button>
                        </p>
                    </b-field>

                </div>
            </div>

        </div>

        <!--CONTACTS-->
        <b-field label="Контактные данные"
                 :type="{'is-danger': $_p_formHasError('contacts')}"
                 :message="$_p_formHasError('contacts') ? $_p_formGetError('contacts') : 'любые каналы связи, по которым с Вами можно связаться'">
            <b-input @input="$_p_formClearError('contacts')"
                     type="textarea"
                     maxlength="500"
                     v-model="artisticProfile.contacts"></b-input>
        </b-field>

        <!--RIDERS-->
        <artistic-profile-form-riders class="field" />

        <!--SUBMIT, CANCEL BUTTONS-->
      <div class="block pt-3 buttons">
          <b-button @click="$router.go(-1)" type="is-secondary" v-text="$t('Cancel')" size="is-medium"></b-button>
          <b-button type="is-success"
                    size="is-medium"
                    :class="{'is-loading': formBusy}"
                    @click.prevent="handleFormSubmit">
            {{ artisticProfile.published ? $t('Update') : $t('Publish') }}
          </b-button>
      </div>
      <b-field>
        </b-field>

    </form>

</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import ArtisticProfileFormGallery from "./parts/ArtisticProfileFormGallery";
    import ArtisticProfileFormRiders from "./parts/ArtisticProfileFormRiders";
    import modelRelationsHelperMixin from "@/mixins/modelRelationsHelperMixin";
    import AdditionalLinks from "./parts/common/AdditionalLinks";
    import CitiesList from "./parts/common/CitiesList";
    import {
        ADD_ARTISTIC_PROFILE_FEE_OPTION,
        ADD_ARTISTIC_PROFILE_LINK,
        DELETE_ARTISTIC_PROFILE_FEE_OPTION,
        DELETE_ARTISTIC_PROFILE_LINK,
        SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS,
        SET_EDITED_ARTISTIC_PROFILE,
        UPDATE_ARTISTIC_PROFILE_IN_LIST
    } from "@/store/modules/artisticProfiles/mutation-types";
    import {GET_DATA_FOR_CREATION} from "@/store/modules/base/action-types";
    import {
        ACTIVE_ARTISTIC_PROFILE_CREATION,
        ACTIVE_ARTISTIC_PROFILE_EDITING
    } from "@/store/modules/artisticProfiles/getters-types";
    import {
        DESTROY_ARTISTIC_PROFILE,
        UPDATE_ARTISTIC_PROFILE
    } from "@/store/modules/artisticProfiles/actions-types";
    import ArtisticProfileYoutubeLink from "@/components/Forms/parts/ArtisticProfileYoutubeLink";

    export default {
        name: "ArtisticProfileForm",
        components: {
            ArtisticProfileYoutubeLink,
            CitiesList, AdditionalLinks, ArtisticProfileFormRiders, ArtisticProfileFormGallery},
        data(){
            return {
                createMode: false,
                artisticProfile: null,
                feeCurrency: 'UAH',
                feeAmount: null,
                feeDescription: '',
                stylisticOptions: {
                    musical: [],
                    dancing: [],
                    show: null,
                },
                features: {},
            };
        },
        methods: {
            ...mapMutations('artisticProfiles',[
                SET_EDITED_ARTISTIC_PROFILE,
                ADD_ARTISTIC_PROFILE_LINK,
                DELETE_ARTISTIC_PROFILE_LINK,
                ADD_ARTISTIC_PROFILE_FEE_OPTION,
                DELETE_ARTISTIC_PROFILE_FEE_OPTION,
                UPDATE_ARTISTIC_PROFILE_IN_LIST,
                SET_EDITABLE_ARTISTIC_PROFILE_FEE_OPTIONS,
            ]),
            ...mapActions('artisticProfiles', [
                DESTROY_ARTISTIC_PROFILE,
                UPDATE_ARTISTIC_PROFILE,
            ]),
            ...mapActions('base', [
                GET_DATA_FOR_CREATION,
            ]),
            clearInstrumentsAndStylisticOptionsRelation(){
                this.artisticProfile.stylistic_options = this.artisticProfile.instruments = [];

                this.setEditableArtisticProfile(this.artisticProfile);
            },
            addFeeOption(){
                /*Validate fee option data*/
                if (this.feeAmount < 1){
                    this.$_p_formPutError({'fee_amount': ['Укажите сумму']});
                    return;
                }

                let feeOption = {
                    amount: this.feeAmount,
                    description: this.feeDescription,
                    currency: this.feeCurrency,
                };

                this[ADD_ARTISTIC_PROFILE_FEE_OPTION](feeOption);

                this.feeAmount = this.feeDescription = '';
                this.feeCurrency = 'UAH';
            },
            deleteFeeOption(feeOption, index){
                this[DELETE_ARTISTIC_PROFILE_FEE_OPTION]({
                    feeOption,
                    index,
                });
            },
            handleFormSubmit(){
                this.$_p_formDispatch(UPDATE_ARTISTIC_PROFILE, {
                    artisticProfileId: this.editableArtisticProfile.id,
                    data: this.prepareArtisticProfileData(),
                }).then((res) => {
                    if (422 !== res.status){

                        this.$buefy.dialog.confirm({
                            title: this.$t('Project was updated'),
                            message: this.$t('Go to relevant vacancies?'),
                            confirmText: this.$t('Yes'),
                            cancelText: this.$t('No'),
                            onConfirm: () => this.$router.push({name: 'artisticDashboard'}),
                        });

                    }
                }).catch(error => {
                    if (422 === error.response.status){
                        this.$buefy.toast.open({
                            message: this.$t('Please, check form fields'),
                            type: 'is-danger'
                        });
                    }
                });

            },
            prepareArtisticProfileData(){
                let formData = Object.assign({}, this.artisticProfile);

                /*temporary solution before info fields doesn't implements*/
                delete formData.artist_info;
                delete formData.show_info;
                delete formData.team_info;
                /*upload filter and images after profile creation*/
                delete formData.images;
                delete formData.files;

                /* merge stylisticOptions ids to one array */
                formData.stylistic_options = this.stylisticOptions.dancing.concat(this.stylisticOptions.musical);
                /* merge required stylistic options to one array*/
                formData.required_stylistic_options = (null === this.stylisticOptions.show) ? [] : this.stylisticOptions.show;

                formData.published = true;

                /*Add fee options*/
                formData.fee_options = this.editableArtisticProfile.fee_options;
                /*left only one fee option as fee threshold, if selected "agreed" fee type*/
                if ('agreed' === formData.fee_type && formData.fee_options.length > 1){
                    formData.fee_options = formData.fee_options.slice(1);
                }

                return formData;
            },
            loadEditableArtisticProfile(){
                this.artisticProfile = {...this.editableArtisticProfile};
                /*
                * Pluck id for instruments
                * */
                if (this.artisticProfile['instruments'].length){

                    this.artisticProfile['instruments'] = this.artisticProfile['instruments'].map(function(relation){
                        return ('object' === typeof relation && undefined !== relation.id) ? relation.id : relation;
                    });
                } else {
                    this.artisticProfile['instruments'] = [];
                }

                //Pluck ids for features
                if (this.artisticProfile.features && this.artisticProfile.features.length){
                    this.$set(this.artisticProfile, 'features', this.artisticProfile.features.map(f => f.id));
                }

                /*Sort stylistic options by type to component local data and pluck ids*/
                this.stylisticOptionTypes.forEach(type => {
                    /*get all options with particular type and*/
                    let attachedArtisticProfileStylisticOptions = this.artisticProfile.stylistic_options.filter(option => {
                        return option.type_id === type.id;
                    }).map(option => {
                        return ('object' === typeof option && undefined !== option.id) ? option.id : option;
                    });

                    /*save attached stylistic options to local variable if exists or keep default local values*/
                    if (attachedArtisticProfileStylisticOptions.length){
                        this.stylisticOptions[type.name] = attachedArtisticProfileStylisticOptions;
                    }
                });
            },
        },
        computed: {
            ...mapState({
                editableArtisticProfile: state => state.artisticProfiles.editedProfile,
                artisticProfileTypes: state => state.artisticProfileTypes.types,
                instruments: state => state.instruments.instruments,
                stylisticOptionTypes: state => state.stylisticOptionTypes.types,
                cities: state => state.cities.cities,
                artisticProfileFeatures: state => state.artisticProfileFeatures.features
            }),
            ...mapGetters('artisticProfiles' ,[
                ACTIVE_ARTISTIC_PROFILE_CREATION,
                ACTIVE_ARTISTIC_PROFILE_EDITING,
            ]),
            musicalStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'musical' === type.name;
                }).stylistic_options;
            },
            dancingStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'dancing' === type.name;
                }).stylistic_options;
            },
            showStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'show' === type.name;
                }).stylistic_options;
            },
        },
        async mounted() {
            this.$Progress.start();
            await this[GET_DATA_FOR_CREATION]();
            this.loadEditableArtisticProfile();
            this.$Progress.finish();
        },
        watch: {
            editableArtisticProfile() {
                this.loadEditableArtisticProfile();
            },
        },
        mixins:[formHelperMixin, modelRelationsHelperMixin],
    };
</script>

<style scoped>

</style>