<template>
    <div >
        <b-progress :max="total" :value="loaded" class="mt-3" type="is-primary" show-value v-if="current_file_name">
            {{ current_file_name }}
        </b-progress>

        <div v-if="queue && queue.length" class="mt-1">
            <label>{{$t('waiting for downloads') }}: </label>
            <div class="has-text-grey">
                <div class="py-1 pl-0" v-for="file in queue" :key="file.name">{{file.name}}</div>
            </div>
        </div>

        <div v-if="failed && failed.length" class="mt-1">
            <label>{{ $t('uploading error') }}:</label>
            <div class="has-text-danger">
                <div class="py-1 pl-0"
                     v-for="(error) in failed"
                     :key="'fail-' + error.fileName">
                    <div>{{error.fileName | truncate(80)}}</div>
                </div>
            </div>
            <span class="mt-3 button is-danger is-small" style="cursor: pointer" @click.prevent="forgetErrors">{{$t('clear errors')}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "QueuedFilesProgressBar",
    props: ['queue', 'current_file_name', 'total', 'loaded', 'failed'],
    methods: {
        forgetError(errorIndex){
            this.$emit('forget-error', errorIndex);
        },
        forgetErrors(){
            this.$emit('forget-errors');
        }
    },
};
</script>

<style scoped>

</style>
