<template>
    <div class="field">
        <label class="label" v-text="fieldLabel"> </label>

        <div class="level is-mobile mt-3" v-for="(link,index) in editableModel.links" :key="'link-' + index" >
            <div class="level-left">
                <div class="level-item">
                    <a :href="link.href">{{link.text}}</a>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-button size="is-small" @click="deleteLink(link,index)" outlined type="is-danger" icon-left="times"></b-button>
                </div>

            </div>
        </div>

        <!--<b-field v-for="(link,index) in editableModel.links" :key="'link-' + index" >

            <b-input v-model="link.href" disabled expanded></b-input>
            <b-input v-model="link.text" disabled expanded></b-input>
            <p class="control">
                <b-button @click="deleteLink(link,index)" type="is-danger" icon-left="times"></b-button>
            </p>

        </b-field>-->

        <b-field :type="{'is-danger': errors.length}">

            <b-input v-model="linkHref"
                     @input="errors = []"
                     :placeholder="$t('Link')"
                     expanded></b-input>
        </b-field>

        <b-field :type="{'is-danger': errors.length}">
            <b-input v-model="linkText"
                     @input="errors = []"
                     expanded
                     :placeholder="$t('Link text')"></b-input>
        </b-field>

        <b-field>
            <p class="control has-text-right">
                <b-button @click="addLink" size="is-small" type="is-primary">{{$t('add')}}</b-button>
            </p>
        </b-field>

        <p class="help is-danger" v-if="errors.length" v-text="errors.join(', ')"></p>

    </div>
</template>

<script>
    export default {
        props: {
            editableModel: Object,
            fieldLabel: String,
        },
        data: function () {
            return {
                errors: [],
                linkHref: '',
                linkText: '',
            };
        },
        methods: {
            addLink(){
                if (!this.validate()){
                    return;
                }
                this.$emit('add-link', {
                    href: this.linkHref,
                    text: this.linkText
                });
                this.linkHref = this.linkText = '';
            },
            deleteLink(link, index){
                this.$emit('delete-link', {link: link, index: index});
            },
            validate(){
                let errors = [];

                if (!this.linkHref){
                    errors.push('Добавьте ссылку');
                }
                if (!this.linkText){
                    errors.push('Добавьте текст ссылки');
                }
                this.errors = errors;
                return this.linkText && this.linkHref;
            },
        },
        name: "AdditionalLinks"
    };
</script>

<style scoped>

</style>