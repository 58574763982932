<template>
    <div>
        <b-field :label="$t('Your best promo')" :message="$t('Youtube link on your best or latest promo video')" >
<!--            <b-input v-model="artisticProfile.prime_promo_link" ></b-input>-->
            <b-input v-model="link" @input="$emit('updated', link)" ></b-input>
        </b-field>

        <b-field>
            <div class="iframe-container"
                 v-if="youtubeEmbedVideoLink" >
                <iframe
                    :src="youtubeEmbedVideoLink"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
        </b-field>

    </div>

</template>

<script>
export default {
    name: "ArtisticProfileYoutubeLink",
    props: {
        artisticProfile: Object,
    },
    data(){
        return {
            link: null,
        };
    },
    created() {
        this.link = this.artisticProfile.prime_promo_link || null;
    },
    computed: {
        youtubeEmbedVideoLink(){
            return this.$options.filters.getYoutubeEmbedLink(this.artisticProfile.prime_promo_link);
        },
    }
};
</script>

<style scoped lang="scss">
    .iframe-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>